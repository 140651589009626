
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination, addMoneyMask } from "@/libs/utils";
import { EstablishmentProcedure } from "@/store/establishment/procedure/types";

const VEstablishmentProcedureList = defineComponent({
  name: "VEstablishmentProcedureList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de procedimentos | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilter({ ignore: ["cd_estabelecimento"] });

    const procedures = computed(() => store.state.establishment.procedure.list);
    const currentEstablishment = computed(
      () => store.state.establishment.list.find((item) => item.id === Number(route.query.cd_estabelecimento)) || null
    );
    const loading = reactive({ list: false });

    const groupOptions = [
      { label: "Consulta", value: "C" },
      { label: "Imagem", value: "I" },
      { label: "Laboratorial", value: "L" },
      { label: "Procedimento", value: "P" },
    ];

    function getDropdownOptions(procedure: EstablishmentProcedure) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "establishment-procedure-edit", params: { _id: String(procedure.id) }, query: route.query },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "establishment-procedure-edit", params: { _id: String(procedure.id) }, query: route.query },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "establishment-procedure-delete", params: { _id: String(procedure.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Procedimentos",
        icon: "fas fa-briefcase-medical mr-1",
        to: { name: "establishment-procedure-list", query: route.query },
      },
      {
        label: "Negociados",
        icon: "fas fa-hand-holding-heart mr-1",
        to: { name: "establishment-procedure-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "establishment.procedure",
      setPage: "setEstablishmentProcedurePaginationPage",
      updateList: getEstablishmentProcedures,
    });

    async function getEstablishmentProcedures() {
      loading.list = true;

      if (route.query.cd_estabelecimento)
        await store.dispatch("searchEstablishmentProcedures", {
          search: { cd_estabelecimento: route.query.cd_estabelecimento },
          isPrimary: true,
          usePagination: true,
        });
      else await store.dispatch("getEstablishmentProcedures");

      loading.list = false;
    }

    if (route.name === "establishment-procedure-list" && hasFilters.value)
      router.push({ name: "establishment-procedure-filter", query: route.query });
    else getEstablishmentProcedures();

    if (route.query.cd_estabelecimento) store.dispatch("getEstablishments");

    return {
      ...{ procedures, currentEstablishment, pagination, loading, breadcrumb, hasFilters, groupOptions },
      ...{ getDropdownOptions, handleUpdateCurrentPage, getEstablishmentProcedures },
      ...{ addMoneyMask },
    };
  },
});

export default VEstablishmentProcedureList;
